import {BrowserOptions} from '@sentry/react';
import {matchPath} from 'react-router-dom';
import {Page} from '@ytsaurus-ui-platform/src/shared/constants/settings';

const ROUTES = [
    `/:cluster/${Page.BAN}`,
    `/:cluster/${Page.MAINTENANCE}`,
    `/:cluster/${Page.COMPONENTS}`,
    `/:cluster/${Page.OPERATIONS}`,
    `/:cluster/${Page.JOB}`,
    `/:cluster/${Page.DASHBOARD}`,
    `/:cluster/${Page.SYSTEM}`,
    `/:cluster/${Page.ACCOUNTS}`,
    `/:cluster/${Page.TABLET_CELL_BUNDLES}`,
    `/:cluster/${Page.CHAOS_CELL_BUNDLES}`,
    `/:cluster/${Page.NAVIGATION}`,
    `/:cluster/${Page.PATH_VIEWER}`,
    `/:cluster/${Page.TABLET}`,
    `/:cluster/${Page.USERS}`,
    `/:cluster/${Page.GROUPS}`,
    `/:cluster/${Page.SCHEDULING}`,
    `/:cluster/${Page.CHYT}`,
    `/:cluster/${Page.QUERIES}`,
    '/:cluster/:page/:tab?',
    '/:cluster/',
];

const sentryMatchPath = (path: string) => {
    return matchPath(path, ROUTES)?.path ?? path;
};
export const sentrySrubbingPaths: NonNullable<BrowserOptions['beforeSend']> = (event) => {
    try {
        if (event.breadcrumbs) {
            event.breadcrumbs = event.breadcrumbs.map((breadcrumb) => {
                if (breadcrumb.category === 'navigation' && breadcrumb.data) {
                    breadcrumb.data.from = sentryMatchPath(breadcrumb.data.from);
                    breadcrumb.data.to = sentryMatchPath(breadcrumb.data.to);
                }

                return breadcrumb;
            });
        }

        if (event.request && event.request.url) {
            event.request.url =
                location.origin + sentryMatchPath(location.pathname) + location.search;
        }

        return event;
    } catch (e) {
        console.error(e);

        return event;
    }
};
