export const LOAD_JOB_DATA_REQUEST = 'JOB:GENERAL:LOAD_JOB_DATA_REQUEST';
export const LOAD_JOB_DATA_SUCCESS = 'JOB:GENERAL:LOAD_JOB_DATA_SUCCESS';
export const LOAD_JOB_DATA_FAILURE = 'JOB:GENERAL:LOAD_JOB_DATA_FAILURE';
export const LOAD_JOB_DATA_CANCELLED = 'JOB:GENERAL:LOAD_JOB_DATA_CANCELLED';

export const LOAD_JOB_COMPETITORS_REQUEST = 'JOB:GENERAL:LOAD_JOB_COMPETITORS_REQUEST';
export const LOAD_JOB_COMPETITORS_SUCCESS = 'JOB:GENERAL:LOAD_JOB_COMPETITORS_SUCCESS';
export const LOAD_JOB_COMPETITORS_FAILURE = 'JOB:GENERAL:LOAD_JOB_COMPETITORS_FAILURE';
export const LOAD_JOB_COMPETITORS_CANCELLED = 'JOB:GENERAL:LOAD_JOB_COMPETITORS_CANCELLED';

export const LOAD_JOB_SPECIFICATION_REQUEST = 'JOB:GENERAL:LOAD_JOB_SPECIFICATION_REQUEST';
export const LOAD_JOB_SPECIFICATION_SUCCESS = 'JOB:GENERAL:LOAD_JOB_SPECIFICATION_SUCCESS';
export const LOAD_JOB_SPECIFICATION_FAILURE = 'JOB:GENERAL:LOAD_JOB_SPECIFICATION_FAILURE';
export const LOAD_JOB_SPECIFICATION_CANCELLED = 'JOB:GENERAL:LOAD_JOB_SPECIFICATION_CANCELLED';
export const CHANGE_OMIT_NODE_DIRECTORY = 'JOB:SPECIFICATION:CHANGE_OMIT_NODE_DIRECTORY';
export const CHANGE_OMIT_INPUT_TABLES_SPECS = 'JOB:SPECIFICATION:CHANGE_OMIT_INPUT_TABLES_SPECS';
export const CHANGE_OMIT_OUTPUT_TABLES_SPECS = 'JOB:SPECIFICATION:CHANGE_OMIT_OUTPUT_TABLES_SPECS';

export const Tab = {
    DETAILS: 'details',
    ATTRIBUTES: 'attributes',
    STATISTICS: 'statistics',
    SPECIFICATION: 'specification',
};

export const DEFAULT_TAB = Tab.DETAILS;
