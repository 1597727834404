import {BrowserOptions} from '@sentry/react';

export const getDefaultSentryOptions = ({
    dsn,
    enabled,
    environment,
    release,
    beforeSend,
}: BrowserOptions) => {
    return {
        dsn,
        enabled,
        environment,
        release,
        allowUrls: [/.*nebius\.dev/gi],
        ignoreErrors: [
            'top.GLOBALS',
            'canvas.contentDocument',
            'ComboSearch is not defined',
            'atomicFindClose',
            'EBCallBackMessageReceived',
            'Failed to fetch',
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
        ],
        denyUrls: [
            // Chrome extensions
            /^chrome:\/\//i,
            /^chrome(-extension)?:\/\//i,
            /safari-(web-)?extension:/i,
            /extensions\//i,
            /graph\.facebook\.com/i,
            /connect\.facebook\.net\/en_US\/all\.js/i,
            /localhost/i,
        ],
        normalizeDepth: 5,
        beforeSend,
    } as BrowserOptions;
};
